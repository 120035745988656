
import './App.css';
import Index from './Home';

function App() {
  return (
    <div className='app'>
       <Index/>
    </div>
  );
}

export default App;
