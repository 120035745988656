import React from "react";
import Navbar from "../Navbar/navigation";
import Body from "../Body/Index";
import Footer from "../Footer/footer";

function Index() {
  return (
    <div>
      <header>
        <Navbar />
      </header>
      <body>
        <Body />
      </body>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default Index;
