import React from "react";
import { FaGooglePlay, FaApple } from 'react-icons/fa';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import mixpanel from "mixpanel-browser";
const firebaseConfig = {
  apiKey: "AIzaSyCB840YSy0xk7pCv_yoI4iQiHqiLG0_nuw",
  authDomain: "malayali-dating.firebaseapp.com",
  projectId: "malayali-dating",
  storageBucket: "malayali-dating.appspot.com",
  messagingSenderId: "522689014048",
  appId: "1:522689014048:web:b6785ecf17650880ed4f45",
  measurementId: "G-XNP2FCPKK6"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
function LeftContent() {
  const clickButton = (source) => (event) => {
    event.preventDefault(); // Prevent the default anchor behavior
    // logEvent(event); // Assuming logEvent is defined elsewhere
    // console.log(event);
    // console.log(`Clicked from ${source}`);
    console.log(source)

    // Handle link navigation programmatically
    if (source === "playstore") {
      window.location.href = "https://play.google.com/store/apps/details?id=com.malayali_datingapp&hl=en&gl=US";
    } else if (source === "appstore") {
      window.location.href = "https://apps.apple.com/us/app/hridhayam/id6444903823";
    }
  };

  return (
    <>
      <div className="left-content">
        <h1>Find love, Malayali-style. Download Hridhayam now!</h1>
        <p>
          No need for a 'tactics meeting' to find love.
          Hridhayam keeps it straightforward for Malayalis. Join us and keep it light.
        </p>
        <div className="left-buttons">
          <a 
            href="#" 
            className="button" 
            onClick={clickButton("playstore")}
          >
            <FaGooglePlay className="icon" /> GOOGLE PLAY
          </a>
          <a 
            href="#" 
            className="button" 
            onClick={clickButton("appstore")}
          >
            <FaApple className="icon" /> APP STORE
          </a>
        </div>
      </div>
    </>
  );
}
const clickButton = (source) => (event) => {

  logEvent(event);
  console.log(event);
  // Your logic here
  console.log(`Clicked from ${source}`);
};

export default LeftContent;
