import React from 'react'

function RightContent() {
  return (
    <>
      <div className="right-img">
        <img src="./sh.png" alt="Sample Image" />
      </div>

    </>
  )
}

export default RightContent