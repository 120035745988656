import React from "react";

function Footer() {
  return (
    <div className="footer-clean">
      <p class="copyright">Hridhayam © 2023</p>
    </div>
  );
}

export default Footer;
